<template>
    <div class="set-service-data">
        <div class="service-data-btn">
            <div>
                <el-button
                        class="white-background-btn"
                        type="primary"
                        @click="deleteRobot"
                >删除
                </el-button
                >
                <el-button
                        class="white-background-btn"
                        type="primary"
                        @click="allUnfold(1)"
                >全部展开
                </el-button
                >
                <el-button
                        class="white-background-btn"
                        type="primary"
                        @click="allUnfold(2)"
                >全部收起
                </el-button
                >
            </div>
            <div style="display: flex;">
    
            <el-upload class="logo-uploader"
                :action="uploadCover"
                :headers="headersParams"
                name="file"
                :show-file-list="false"
                accept=".xls, .xlsx"
                :data="{id:this.$route.query.id}"
                :on-success="handleCoverSuccess"
                :before-upload="beforeCoverUpload">
                <el-button class="btn-blue" style="margin-right: 10px">导入机器人</el-button>
            </el-upload>
            <el-button class="btn-blue" @click="onExport">导出机器人</el-button>
            <el-button type="primary" @click="addRobotVisible = true"><i class="el-icon-plus"></i> 添加机器人</el-button>
            </div>
            <!--  添加机器人弹框  -->
            <el-dialog
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                    :title="addRobotForm.title"
                    custom-class="green-dialog2"
                    :visible.sync="addRobotVisible"
                    @close="addRobotResetForm"
                    @opened="openAddRobot"
                    width="500px">
                <el-form
                        ref="addRobotForm"
                        :model="addRobotForm"
                        :rules="addRobotRules"
                        label-width="120px">
                    <el-form-item label="机器人名称" prop="name">
                        <el-input
                                v-model="addRobotForm.name"
                                style=""
                                ref="cursorInput">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="咨询商品" prop="good_id">
                        <el-select v-model="addRobotForm.good_id" placeholder="选择商品" clearable>
                            <el-option
                                    v-for="item in stepTwoGoods"
                                    :key="item.good_id"
                                    :label="item.goods_name"
                                    :value="item.good_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否已购买" prop="is_have">
                        <el-radio-group v-model="addRobotForm.is_have">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                            label="订单编号"
                            prop="order_sn"
                            v-if="addRobotForm.is_have === 1">
                        <el-input
                                v-model="addRobotForm.order_sn"
                                style="width: 246px"
                                placeholder="12位数字">
                        </el-input>
                        <el-button plain style="margin-left: 10px" @click="generateOrder">随机生成</el-button>
                    </el-form-item>
                    <el-form-item label="下单时间" required v-if="addRobotForm.is_have === 1">
                        <el-date-picker
                                prop="order_time"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                v-if="addRobotForm.is_have === 1"
                                v-model="addRobotForm.order_time">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item
                            label="收件人"
                            prop="recipients"
                            v-if="addRobotForm.is_have === 1">
                        <el-input v-model="addRobotForm.recipients"></el-input>
                    </el-form-item>
                    <el-form-item
                            label="联系方式"
                            prop="contact"
                            v-if="addRobotForm.is_have === 1">
                        <el-input v-model="addRobotForm.contact"></el-input>
                    </el-form-item>
                    <el-form-item
                            label="收货地址"
                            prop="address"
                            v-if="addRobotForm.is_have === 1">
                        <el-input type="textarea" v-model="addRobotForm.address"></el-input>
                    </el-form-item>
                </el-form>
                <div class="dialog-footer">
                    <el-button @click="addRobotVisible = false">取 消</el-button>
                    <el-button
                            style="margin-left: 30px"
                            type="primary"
                            @click="saveAddRobot('addRobotForm')"
                    >保存
                    </el-button>
                </div>
            </el-dialog>
            <!--  添加问题弹框  -->
            <el-dialog
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                    title="添加问题"
                    custom-class="green-dialog2"
                    :visible.sync="addIssueVisible"
                    @close="issueResetForm"
                    @opened="openAddIssue"
                    width="500px">
                <el-form
                        ref="addIssueForm"
                        :model="addIssueForm"
                        :rules="addIssueRules"
                        label-width="120px">
                    <el-form-item label="问题类型" prop="type">
                        <el-select
                                v-model="addIssueForm.type"
                                placeholder="选择"
                                @change="selectScorePperate" clearable>
                            <el-option
                                    v-for="item in issueType"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="考点名称" prop="name">
                        <el-input v-model="addIssueForm.name" ref="cursorInput"></el-input>
                    </el-form-item>
                    <el-form-item label="客户问题" prop="question">
                        <el-input
                                type="textarea"
                                v-model="addIssueForm.question">
                        </el-input>
                    </el-form-item>
                    <el-form-item
                            label="标准答案"
                            v-if="addIssueForm.type === 1"
                            prop="answer">
                        <el-input type="textarea" v-model="addIssueForm.answer"></el-input>
                    </el-form-item>
                    <el-form-item
                            label="得分操作"
                            v-if="addIssueForm.type === 1"
                            prop="score_operate">
                        <el-input
                                type="textarea"
                                placeholder="用#分割"
                                v-model="addIssueForm.score_operate">
                        </el-input>
                    </el-form-item>
                    <el-form-item
                            label="得分操作"
                            v-if="addIssueForm.type === 2"
                            prop="score_operate_two">
                        <el-select
                                v-model="addIssueForm.score_operate_two"
                                @change="selectScorePperate" clearable>
                            <el-option
                                    v-for="item in scoreConfigs"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            label="优惠券"
                            v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 1"
                            prop="coupon_id">
                        <el-select
                                v-model="addIssueForm.coupon_id"
                                placeholder="选择优惠券" clearable>
                            <el-option
                                    v-for="(item, index) in selectCouponList"
                                    :key="index"
                                    :label="item.name"
                                    :value="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            label="直赔原因"
                            v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 2"
                            prop="reason">
                        <el-select
                                v-model="addIssueForm.reason"
                                placeholder="请选择直赔原因" clearable>
                            <el-option
                                    v-for="item in compensateList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            label="直赔金额"
                            v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 2"
                            prop="money">
                        <el-input-number
                                :controls="false"
                                v-model="addIssueForm.money"
                                :precision="2"
                                :min="0.01">
                        </el-input-number>
                    </el-form-item>
                    <el-form-item
                            label="商品"
                            v-if="(addIssueForm.type === 2 && addIssueForm.score_operate_two === 3) || addIssueForm.score_operate_two === 4"
                            prop="good_id">
                        <el-select v-model="addIssueForm.good_id" placeholder="请选择商品" clearable>
                            <el-option
                                    v-for="item in stepTwoGoods"
                                    :key="item.good_id"
                                    :label="item.goods_name"
                                    :value="item.good_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            label="类型"
                            v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 4" prop="couponType_id">
                        <el-select
                                v-model="addIssueForm.couponType_id"
                                placeholder="请选择优惠券类型" clearable
                        >
                            <el-option
                                    v-for="item in discountsTypeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            label="优惠"
                            v-if="
              addIssueForm.type === 2 && addIssueForm.score_operate_two === 4
            "
                            class="is-required"
                    >
                        <el-col :span="12">
                            <el-form-item prop="price_man">
                                满
                                <el-input-number
                                        @change="cullSubtract"
                                        style="width: 120px; margin: 0 4px"
                                        :controls="false"
                                        v-model="addIssueForm.price_man"
                                        :precision="2"
                                        :min="0.01"
                                ></el-input-number
                                >
                                元，
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="price_jian">
                                减
                                <el-input-number
                                        @change="cullSubtract"
                                        style="width: 120px; margin: 0 4px"
                                        :controls="false"
                                        v-model="addIssueForm.price_jian"
                                        :precision="2"
                                        :min="0.01"
                                ></el-input-number
                                >
                                元
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item
                            label="有效期"
                            v-if="
              addIssueForm.type === 2 && addIssueForm.score_operate_two === 4
            "
                            prop="validity_time"
                    >
                        <el-select
                                v-model="addIssueForm.validity_time"
                                placeholder="请选择优惠券有效期" clearable
                        >
                            <el-option
                                    :key="1"
                                    label="当日23:59:59前使用有效"
                                    :value="1"
                            ></el-option>
                            <el-option
                                    :key="2"
                                    label="次日23:59:59前使用有效"
                                    :value="2"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            label="不得分关键词"
                            v-if="addIssueForm.type === 1"
                            prop="no_score_keyword"
                    >
                        <el-input
                                type="textarea"
                                v-model="addIssueForm.no_score_keyword"
                                placeholder="用#分割"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="解析" prop="analysis">
                        <el-input
                                type="textarea"
                                v-model="addIssueForm.analysis"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="设置分值" prop="score">
                        <el-input-number
                                :controls="false"
                                v-model="addIssueForm.score"
                                :precision="1"
                                :min="0.1"
                                :max="100"
                        ></el-input-number>
                    </el-form-item>
                </el-form>
                <div class="dialog-footer">
                    <el-button @click="addIssueVisible = false">取 消</el-button>
                    <el-button
                            style="margin-left: 30px"
                            @click="saveAddIssue('addIssueForm')"
                            type="primary"
                    >保存
                    </el-button
                    >
                </div>
            </el-dialog>
        </div>
        <div class="service-data-table">
            <div class="data-table-header">
                <div class="table-header-item" :style="flexList[0]">
                    <el-checkbox
                            :indeterminate="isIndeterminate"
                            @change="handleCheckAllChange"
                            style="margin-right: 6px"
                            v-model="checkAll"
                    ></el-checkbox>
                    <span>机器人名称</span>
                </div>
                <div class="table-header-item" :style="flexList[1]">
                    <span>咨询商品</span>
                </div>
                <div class="table-header-item" :style="flexList[2]">
                    <span>问题类型</span>
                </div>
                <div class="table-header-item" :style="flexList[3]">
                    <span>客户问题</span>
                </div>
                <div class="table-header-item" :style="flexList[4]">
                    <span>得分操作</span>
                </div>
                <div class="table-header-item">
                    <span>分值（总分{{ totalScoreNum.toFixed(1) }}分）</span>
                </div>
                <div class="table-header-item" :style="flexList[6]" style="text-align: center">
                    <span>移动</span>
                </div>
                <div class="table-header-item" :style="flexList[5]">
                    <span>操作</span>
                </div>
            </div>
            <div class="data-table-body" v-if="isShow">
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <div class="table-body-item" v-for="(item, index) in robotList" :key="index">
                        <div class="body-item-top">
                            <div class="item-top-item" :style="flexList[0]">
                                <i style="font-size: 12px" @click="isShowClick(item)"  v-show="item.isShow"
                                   class="iconfont">&#xe633;</i>
                                <i style="font-size: 16px" @click="isShowClick(item)"  v-show="!item.isShow"
                                   class="iconfont">&#xe602;</i>
                                <el-checkbox style="margin-right: 6px" :key="index" :label="index"></el-checkbox>
                                <span class="text-overflow">{{ item.name }}</span>
                            </div>
                            <div class="item-top-item" :style="flexList[1]">
                                <span class="text-overflow">{{goodsFormat(item.good_id)}}</span>
                            </div>
                            <div class="item-top-item" :style="flexList[2]">
                                <span></span>
                            </div>
                            <div class="item-top-item" :style="flexList[3]">
                                <span class="text-overflow"></span>
                            </div>
                            <div class="item-top-item" :style="flexList[4]">
                                <span class="text-overflow"></span>
                            </div>
                            <div class="item-top-item"><span></span></div>
                            <div class="item-top-item" :style="flexList[6]">
                                <div style="text-align: center">
                                    <i @click="toppingClick(item)" v-show="index !== 0 && robotList.length !== 1"
                                       class="iconfont">&#xe684;</i>
                                    <i @click="previousClick(item)" v-show="index !== 0 && robotList.length !== 1"
                                       class="iconfont">&#xe685;</i>
                                    <i @click="lowClick(item)" v-show="index !== robotList.length-1 && robotList.length !== 1"
                                       class="iconfont">&#xe686;</i>
                                    <i @click="nextClick(item)" v-show="index !== robotList.length-1 && robotList.length !== 1"
                                       class="iconfont">&#xe687;</i>
                                </div>
                            </div>
                            <div class="item-top-item" :style="flexList[5]">
                                <el-link type="success" @click="editRobot(index)">编辑</el-link>
                                <el-link type="primary" @click="addIssueBtn(index)"
                                >添加问题
                                </el-link
                                >
                            </div>
                        </div>
                        <div class="body-item-bottom" v-for="(questionItem, questionIndex) in item.question" :key="questionIndex" v-show="item.isShow">
                            <div class="item-bottom-item" :style="flexList[0]">
                                <span></span>
                            </div>
                            <div class="item-bottom-item" :style="flexList[1]">
                                <span></span>
                            </div>
                            <div class="item-bottom-item" :style="flexList[2]">
                                <span>{{ questionItem.type === 1 ? "问答题" : "操作题" }}</span>
                            </div>
                            <div class="item-bottom-item" :style="flexList[3]">
                                <span class="text-overflow">{{ questionItem.question }}</span>
                            </div>
                            <div class="item-bottom-item" v-if="questionItem.type === 1" :style="flexList[4]">
                                <span class="text-overflow">{{questionItem.score_operate}}</span>
                            </div>
                            <div class="item-bottom-item" v-else :style="flexList[4]">
                                <span class="text-overflow">{{formatOperate(questionItem.score_operate)}}</span>
                            </div>
                            <div class="item-bottom-item">
                                <span>{{ questionItem.score }}</span>
                            </div>
                            <div class="item-bottom-item" :style="flexList[6]"></div>
                            <div class="item-bottom-item" :style="flexList[5]">
                                <el-link type="success" @click="questionEdit(index, questionIndex)">编辑
                                </el-link>
                                <el-link type="danger" @click="questionDelete(index, questionIndex)">删除
                                </el-link>
                            </div>
                        </div>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="table-noData" v-else>暂无数据</div>
        </div>
    </div>
</template>

<script>
    import {getKeFuCreateSN} from "@/utils/apis";




    export default {
        name: "createStepThree",
        props: [
            "stepTwoGoods",
            "editData",
            "editChangStepThree",
            "createRobotData",
            "createChangStepThree",
        ],
        data() {
            // 订单编号验证
            var validateOrder = (rule, value, callback) => {
                if (value.length !== 12) {
                    callback(new Error("订单编号为12位数字"));
                } else {
                    let numReg = /^[0-9]*$/;
                    let numRe = new RegExp(numReg);
                    if (!numRe.test(value)) {
                        callback(new Error("订单编号格式错误"));
                    } else {
                        callback();
                    }
                }
            };
            // 手机号验证
            var validateContact = (rule, value, callback) => {
                let reg = /^1[345789]\d{9}$/;
                if (!reg.test(value)) {
                    callback(new Error("联系方式格式有误"));
                } else {
                    callback();
                }
            };
            return {
                totalScoreNum: 0,
                flexList: [
                    {
                        flex: 0.7,
                    },
                    {
                        flex: 0.84,
                    },
                    {
                        flex: 0.8,
                    },
                    {
                        flex: 1.25,
                    },
                    {
                        flex: 1.25,
                    },
                    {
                        flex: 0.8,
                    },
                    {
                        flex: 0.4,
                    },
                ],
                robotList: [],
                show: false,
                checkedCities: [],
                isIndeterminate: false,
                checkAll: false,
                goodsList: [
                    {
                        name: "测试商品",
                        id: 1,
                    },
                ],
                addRobotVisible: false, // 添加机器人弹框
                addIssueVisible: false, // 添加问题弹框
                addRobotForm: {
                    // 新增机器人表单
                    id: "",
                    name: "",
                    good_id: "",
                    is_have: 0,
                    order_sn: "",
                    order_time: "",
                    recipients: "",
                    contact: "",
                    address: "",
                    title: "",
                },
                addRobotRules: {
                    name: [
                        {required: true, message: "请输入机器人名称", trigger: "blur"},
                        {
                            min: 1,
                            max: 50,
                            message: "长度在 1 到 50 个字符",
                            trigger: "blur",
                        },
                    ],
                    good_id: [
                        {required: true, message: "请选择咨询商品", trigger: "change"},
                    ],
                    is_have: [
                        {required: true, message: "请选择是否购买", trigger: "change"},
                    ],
                    order_sn: [
                        {required: true, message: "请输入订单编号", trigger: "blur"},
                        {validator: validateOrder, trigger: "blur"},
                    ],
                    order_time: [
                        {required: true, message: "请输入下单时间", trigger: "blur"},
                    ],
                    recipients: [
                        {required: true, message: "请输入收件人", trigger: "blur"},
                    ],
                    contact: [
                        {required: true, message: "请输入联系方式", trigger: "blur"},
                        {validator: validateContact, trigger: "blur"},
                    ],
                    address: [
                        {required: true, message: "请输入收货地址", trigger: "blur"},
                    ],
                },
                issueType: [
                    {
                        id: 1,
                        name: "问答题",
                    },
                    {
                        id: 2,
                        name: "操作题",
                    },
                ],
                scoreConfigs: [],
                scoreOperation: [
                    {
                        id: 1,
                        name: "发送商品优惠券",
                        isShow: true,
                    },
                    {
                        id: 2,
                        name: "申请直赔",
                        isShow: true,
                    },
                    {
                        id: 3,
                        name: "发送商品",
                        isShow: true,
                    },
                    {
                        id: 4,
                        name: "发送新建优惠券",
                        isShow: true,
                    },
                    {
                        id: 5,
                        name: "发送核对订单",
                        isShow: true,
                    },
                ],
                addIssueIndex: "", // 添加问题索引
                addIssueForm: {
                    //新增问题表单
                    index: "",
                    questionIndex: "",
                    type: 1,
                    name: "",
                    question: "",
                    answer: "",
                    score_operate: "",
                    score_operate_two: 1,
                    no_score_keyword: "",
                    analysis: "",
                    score: void 0,
                    coupon_id: "",
                    reason: "",
                    money: void 0,
                    good_id: "",
                    couponType_id: "",
                    price_man: void 0,
                    price_jian: void 0,
                    validity_time: "",
                },
                addIssueRules: {
                    type: [
                        {required: true, message: "请选择问题类型", trigger: "change"},
                    ],
                    name: [{required: true, message: "请输入考点名称", trigger: "blur"}],
                    question: [
                        {required: true, message: "请输入客户问题", trigger: "blur"},
                    ],
                    answer: [
                        {required: true, message: "请输入标准答案", trigger: "blur"},
                    ],
                    score_operate: [
                        {required: true, message: "请输入得分操作", trigger: "blur"},
                    ],
                    score_operate_two: [
                        {required: true, message: "请选择得分操作", trigger: "change"},
                    ],
                    no_score_keyword: [
                        {required: true, message: "请输入不得分关键词", trigger: "blur"},
                    ],
                    analysis: [{required: true, message: "请输入解析", trigger: "blur"}],
                    score: [{required: true, message: "请输入分数", trigger: "blur"}],
                    coupon_id: [
                        {required: true, message: "请选择优惠券", trigger: "change"},
                    ],
                    reason: [
                        {required: true, message: "请选择直赔原因", trigger: "change"},
                    ],
                    money: [{required: true, message: "请输入直赔金额", trigger: "blur"}],
                    good_id: [{required: true, message: "请选择商品", trigger: "change"}],
                    couponType_id: [
                        {required: true, message: "请选择优惠券类型", trigger: "change"},
                    ],
                    price_man: [{required: true, message: "请输入满元", trigger: "blur"}],
                    price_jian: [
                        {required: true, message: "请输入减元", trigger: "blur"},
                    ],
                    validity_time: [
                        {required: true, message: "请选择优惠券有效期", trigger: "change"},
                    ],
                },
                selectCouponList: [], //优惠券列表
                compensateList: [
                    //直赔原因
                    {
                        name: "运费补偿",
                        id: 1,
                    },
                    {
                        name: "收货后少件补偿",
                        id: 2,
                    },
                    {
                        name: "商品质量问题补偿",
                        id: 3,
                    },
                    {
                        name: "商品描述差异问题补偿",
                        id: 4,
                    },
                    {
                        name: "其他类补偿",
                        id: 5,
                    },
                    {
                        name: "活动返现类补偿",
                        id: 6,
                    },
                    {
                        name: "维修退换货瑕疵类补偿",
                        id: 7,
                    },
                    {
                        name: "运转不及时补偿",
                        id: 8,
                    },
                    {
                        name: "未完成送装服务补偿",
                        id: 9,
                    },
                ],
                discountsTypeList: [
                    {
                        name: "商品优惠券",
                        id: 1,
                    },
                    {
                        name: "店铺优惠券",
                        id: 2,
                    },
                ],
                cityOptions: [],
                isShow: false,
                robotNameRepeat: false,
                uploadCover: this.$api.new_kefuImport,
                headersParams: {
                    Authorization: localStorage.getItem('token')
                },
            };
        },
        mounted() {
            if (this.$route.query.id) {
                let time = setInterval(() => {
                    if (this.editData) {
                        clearInterval(time);
                        this.getEditData();
                    }
                }, 500);
            }
        },
        methods: {
            onExport(){
                if(!this.$route.query.id){
                    this.$message.warning('导出当前保存过的数据!');
                    return false
                }
                
            window.location.href=`/new_kefu/export?id=${this.$route.query.id}&token=${localStorage.getItem("token")}`
              
            },
            beforeCoverUpload(file) {
                // .xls, .xlsx
                const isXls = file.name.toLowerCase().endsWith('.xls') || file.name.toLowerCase().endsWith('.xlsx') ;
                if (!isXls) {
                    this.$message.error('上传图片只能是 xls 或 xlsx格式!');
                    return false;
                }
                return isXls;
            },
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                  
                    // 在此处校验数据是否合法
                    let robotData = JSON.parse(JSON.stringify(res.data));
                    robotData.forEach((item) => {
                        item.isShow = true;
                    });
                    this.robotList = robotData;
                    this.totalScore();
                    this.noData();
                } else {
                    this.$message.error(res.msg)
                }
            },
            isShowClick(val) {
                let arr = []
                this.robotList.forEach((item,index) => {
                    if (item.id === val.id) {
                        this.robotList[index].isShow = !item.isShow
                        arr = this.robotList
                        this.robotList = []
                        this.robotList = arr
                    }
                })
            },
            swapItems(arr, index1, index2) {
                arr[index1] = arr.splice(index2, 1, arr[index1])[0];
                return arr
            },
            // 点击上移
            previousClick(val) {
                let index = this.robotList.findIndex(o => o.id === val.id);
                this.swapItems(this.robotList, index, index - 1);
            },
            // 点击下移
            nextClick(val) {
                let index = this.robotList.findIndex(o => o.id === val.id);
                this.swapItems(this.robotList, index, index+1)
            },
            // 点击置顶
            toppingClick(val) {
                this.robotList.forEach((item, index) => {
                    if (val.id === item.id) {
                        this.robotList.unshift(this.robotList.splice(index, 1)[0])
                    }
                })
            },
            // 点击置低
            lowClick(val) {
                this.robotList.forEach((item, index) => {
                    if (val.id === item.id) {
                        this.robotList.push(this.robotList.splice(index, 1)[0])
                    }
                })
            },
            sortIndex() {
                console.log(this.robotList)
                this.robotList.forEach((item, index) => {
                    item.sort = index
                })
            },
            noData() {
                if (this.robotList.length > 0) {
                    return (this.isShow = true);
                } else {
                    return (this.isShow = false);
                }
            },
            // 获取编辑数据
            getEditData() {
                if (this.editData) {
                    let robotData = JSON.parse(JSON.stringify(this.editData.robot_data));
                    robotData.forEach((item) => {
                        item.isShow = true;
                    });
                    this.robotList = robotData;
                    this.totalScore();
                    this.noData();
                } else if (this.createChangStepThree) {
                    let robotData = JSON.parse(JSON.stringify(this.createChangStepThree));
                    robotData.forEach((item) => {
                        item.isShow = true;
                    });
                    this.robotList = robotData;
                    this.totalScore();
                    this.noData();
                } else if (this.editChangStepThree) {
                    let robotData = JSON.parse(JSON.stringify(this.editChangStepThree));
                    robotData.forEach((item) => {
                        item.isShow = true;
                    });
                    this.robotList = robotData;
                    this.totalScore();
                    this.noData();
                } else {
                    this.isShow = false;
                }
            },
            // 删除机器人
            deleteRobot() {
                if (this.checkedCities.length) {
                    this.$confirm(`是否删除所选机器人，删除将无法恢复`, "删除", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        customClass: "green-message-box",
                        type: "warning",
                    })
                        .then(() => {
                            console.log(this.checkedCities.length )
                            console.log(this.robotList.length )
                            // this.checkedCities.sort();
                            if (this.checkedCities.length == this.robotList.length) {
                                this.robotList.splice(0, this.robotList.length);
                            } else {
                                console.log(this.checkedCities)
                                this.robotList.forEach((item, index) => {
                                    item.sort = index
                                })
                                this.checkedCities.forEach((item, index) => {
                                    this.robotList.forEach((item1, index1) => {
                                        if (item1.sort == item) {
                                            this.robotList.splice(index1, 1);
                                        }
                                    })
                                })
                                // for (let i = this.checkedCities.length - 1; i >= 0; i--) {
                                //     this.robotList.splice(this.checkedCities[i], 1);
                                // }
                            }
                            this.checkedCities = [];
                            this.isIndeterminate = false;
                            this.cityOptions = [];
                            this.robotList.map((item, index) => {
                                this.cityOptions.push(index);
                            });
                            this.totalScore();
                            this.noData();
                            this.isIndeterminate = false;
                            this.checkAll=false
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "已取消删除",
                            });
                        });
                } else {
                    this.$message.warning("请选择所要删除的机器人");
                }
            },
            // 全部展开 收起
            allUnfold(num) {
                let arr = []
                if (num === 1) {
                    this.robotList.forEach((item) => {
                        item.isShow = true;
                        arr = this.robotList
                        this.robotList = []
                        this.robotList = arr
                    });
                } else {
                    this.robotList.forEach((item) => {
                        item.isShow = false;
                        arr = this.robotList
                        this.robotList = []
                        this.robotList = arr
                    });
                }
            },
            // 总共分数
            totalScore() {
                this.totalScoreNum = 0;
                this.robotList.forEach((item) => {
                    item.question.forEach((item2) => {
                        this.totalScoreNum += Number(item2.score);
                    });
                });
            },
            // 格式化操作
            formatOperate(id) {
                for (let i = 0; i < this.scoreOperation.length; i++) {
                    if (this.scoreOperation[i].id === id) {
                        return this.scoreOperation[i].name;
                    }
                }
            },
            // 选择得分操作
            selectScorePperate() {
            
                
                this.scoreOperation[4].isShow = this.robotList[this.addIssueIndex]?.is_have === 0 ? false : true;
                this.scoreConfigs = this.scoreOperation.filter(o => o.isShow === true);
                let num = this.addIssueForm.type;
                let num2 = this.addIssueForm.score_operate_two;
                this.$nextTick(() => {
                    this.$refs.addIssueForm.clearValidate();
                });
                this.addIssueForm.type = num;
                this.addIssueForm.score_operate_two = num2;
            },
            // 商品id格式化
            goodsFormat(id) {
                let str = "";
                if (this.stepTwoGoods) {
                    for (let i = 0; i < this.stepTwoGoods.length; i++) {
                        if (this.stepTwoGoods[i].good_id === id) {
                            str = this.stepTwoGoods[i].goods_name;
                            return str;
                        }
                    }
                } else {
                    return str;
                }
            },
            // 编辑机器人
            editRobot(index) {
                this.addRobotForm.id = index;
                this.addRobotVisible = true;
                this.addRobotForm.title = "编辑机器人";
            },
            // 编辑问题
            questionEdit(index, questionIndex) {
                this.stepTwoGoods.forEach((item) => {
                    if (item.good_id === this.robotList[index].good_id) {
                        this.selectCouponList = item.coupon;
                    }
                });
               
                
                this.addIssueForm.index = index;
                this.addIssueForm.questionIndex = questionIndex;
                this.addIssueForm.name =
                    this.robotList[index].question[questionIndex].name;
                this.addIssueForm.question =
                    this.robotList[index].question[questionIndex].question;
                this.addIssueForm.type =
                    this.robotList[index].question[questionIndex].type;
                this.addIssueForm.analysis =
                    this.robotList[index].question[questionIndex].analysis;
                this.addIssueForm.score =
                    this.robotList[index].question[questionIndex].score;
                if (this.robotList[index].question[questionIndex].type === 1) {
                    this.addIssueForm.answer =
                        this.robotList[index].question[questionIndex].answer;
                    this.addIssueForm.score_operate =
                        this.robotList[index].question[questionIndex].score_operate;
                    this.addIssueForm.no_score_keyword =
                        this.robotList[index].question[questionIndex].no_score_keyword;
                
                    
                } else {
                    this.addIssueForm.score_operate_two =
                        this.robotList[index].question[questionIndex].score_operate;
                    if (this.addIssueForm.score_operate_two === 1) {
                        this.addIssueForm.coupon_id =
                            this.robotList[index].question[questionIndex].coupon_id;
                    } else if (this.addIssueForm.score_operate_two === 2) {
                        this.addIssueForm.reason =
                            this.robotList[index].question[questionIndex].reason;
                        this.addIssueForm.money =
                            this.robotList[index].question[questionIndex].money;
                    } else if (this.addIssueForm.score_operate_two === 3) {
                        this.addIssueForm.good_id =
                            this.robotList[index].question[questionIndex].good_id;
                    } else if (this.addIssueForm.score_operate_two === 4) {
                        this.addIssueForm.good_id =
                            this.robotList[index].question[questionIndex].good_id;
                        this.addIssueForm.couponType_id =
                            this.robotList[index].question[questionIndex].coupon_id;
                        this.addIssueForm.price_man =
                            this.robotList[index].question[questionIndex].price_man;
                        this.addIssueForm.price_jian =
                            this.robotList[index].question[questionIndex].price_jian;
                        this.addIssueForm.validity_time =
                            this.robotList[index].question[questionIndex].validity_time;
                    }
                    
                }
                if (this.robotList[index].is_have === 0) {
                    this.scoreOperation[4].isShow = false;
                } else {
                    this.scoreOperation[4].isShow = true;
                }
                this.scoreConfigs = this.scoreOperation.filter(o => o.isShow === true);
                this.addIssueVisible = true;
            },
            // 满减限制
            cullSubtract() {
                if (this.addIssueForm.price_man && this.addIssueForm.price_jian) {
                    if (this.addIssueForm.price_jian > this.addIssueForm.price_man) {
                        this.$nextTick(() => {
                            this.$set(this.addIssueForm, "price_jian", void 0);
                            this.$message("满减优惠所减价格不能大于已满价格");
                        });
                    }
                }
            },
            // 删除问题
            questionDelete(index, questionIndex) {
                this.$confirm(`是否删除该问题，删除将无法恢复`, "删除", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    customClass: "green-message-box",
                    type: "warning",
                })
                    .then(() => {
                        this.robotList[index].question.splice(questionIndex, 1);
                        this.totalScore();
                       
                        
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            // 新增问题按钮
            addIssueBtn(index) {
                this.addIssueIndex = index;
                if (this.robotList[index].is_have === 0) {
                    this.scoreOperation[4].isShow = false;
                } else {
                    this.scoreOperation[4].isShow = true;
                }
                this.scoreConfigs = this.scoreOperation.filter(o => o.isShow === true);
                this.stepTwoGoods.forEach((item) => {
                    if (item.good_id === this.robotList[index].good_id) {
                        this.selectCouponList = item.coupon;
                    }
                });
                this.addIssueVisible = true;
            },
            // 保存新增机器人
            saveAddRobot(formName) {
                this.noData();
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // 机器人名称重复提醒
                        let robotName = this.robotList.findIndex((val) => {
                            return val.name == this.addRobotForm.name;
                        });
                        if (robotName !== -1) {
                            this.robotNameRepeat = true;
                        } else {
                            this.robotNameRepeat = false;
                        }
                        // 编辑机器人无修改机器人名称直接点击保存
                        if (this.addRobotForm.title == "编辑机器人") {
                            this.robotNameRepeat = false;
                        }
                        if (this.robotNameRepeat !== true) {
                            if (this.addRobotForm.is_have == 0) {
                                let obj = {
                                    name: this.addRobotForm.name,
                                    good_id: this.addRobotForm.good_id,
                                    is_have: 0,
                                    question: [],
                                    isShow: true,
                                };
                                if (this.addRobotForm.id !== "") {
                                    let index = this.addRobotForm.id;
                                    obj.id = this.robotList[index].id;
                                    obj.isShow = this.robotList[index].isShow;
                                    obj.question = this.robotList[index].question;
                                    this.robotList[index] = obj;
                                } else {
                                    this.robotList.push(obj);
                                }
                            } else {
                                let obj = {
                                    name: this.addRobotForm.name,
                                    good_id: this.addRobotForm.good_id,
                                    is_have: 1,
                                    order_sn: this.addRobotForm.order_sn,
                                    order_time: this.addRobotForm.order_time,
                                    recipients: this.addRobotForm.recipients,
                                    contact: this.addRobotForm.contact,
                                    address: this.addRobotForm.address,
                                    question: [],
                                    isShow: true,
                                };
                                if (this.addRobotForm.id !== "") {
                                    let index = this.addRobotForm.id;
                                    obj.id = this.robotList[index].id;
                                    obj.isShow = this.robotList[index].isShow;
                                    obj.question = this.robotList[index].question;
                                    this.robotList[index] = obj;
                                } else {
                                    this.robotList.push(obj);
                                }
                            }
                            this.cityOptions = [];
                            this.robotList.forEach((item, index) => {
                                this.cityOptions.push(index);
                            });
                            this.addRobotVisible = false;
                            if (this.addRobotForm.id !== "") {
                                this.$message.success("修改成功！");
                                this.$emit("createRobotData", this.robotList);
                            } else {
                                this.$message.success("添加成功！");
                                this.$emit("createRobotData", this.robotList);
                            }
                        } else {
                            this.$message.error("机器人名称不能重复！");
                        }
                    } else {
                        return false;
                    }
                });
                this.noData();
            },
            // 保存新增问题
            saveAddIssue(formName) {
                this.$refs[formName].validate((valid) => {
                    // if (valid) {
                        let obj = {
                            name: this.addIssueForm.name,
                            question: this.addIssueForm.question,
                            type: this.addIssueForm.type,
                            analysis: this.addIssueForm.analysis,
                            score: this.addIssueForm.score,
                        };
                        if (this.addIssueForm.type === 1) {
                            obj.answer = this.addIssueForm.answer;
                            obj.score_operate = this.addIssueForm.score_operate;
                            obj.no_score_keyword = this.addIssueForm.no_score_keyword;
                        } else {
                            obj.score_operate = this.addIssueForm.score_operate_two;
                            if (this.addIssueForm.score_operate_two === 1) {
                                obj.coupon_id = this.addIssueForm.coupon_id;
                            } else if (this.addIssueForm.score_operate_two === 2) {
                                obj.reason = this.addIssueForm.reason;
                                obj.money = this.addIssueForm.money;
                            } else if (this.addIssueForm.score_operate_two === 3) {
                                obj.good_id = this.addIssueForm.good_id;
                            } else if (this.addIssueForm.score_operate_two === 4) {
                                obj.good_id = this.addIssueForm.good_id;
                                obj.coupon_id = this.addIssueForm.couponType_id;
                                obj.price_man = this.addIssueForm.price_man;
                                obj.price_jian = this.addIssueForm.price_jian;
                                obj.validity_time = this.addIssueForm.validity_time;
                            }
                        }
                        if (
                            this.addIssueForm.index !== "" &&
                            this.addIssueForm.questionIndex !== ""
                        ) {
                            this.robotList[this.addIssueForm.index].question[
                                this.addIssueForm.questionIndex
                                ] = obj;
                            this.$message.success("修改成功");
                            this.totalScore();
                        } else {
                            this.robotList[this.addIssueIndex].question.push(obj);
                            this.$message.success("添加成功");
                            this.totalScore();
                        }

                        this.addIssueVisible = false;
                    // } else {
                    //     return false;
                    // }
                });
            },
            // 打开添加机器人弹框
            openAddRobot() {
               
                
                if (this.addRobotForm.title === "") {
                    this.addRobotForm.title = "添加机器人";
                }
                if (this.addRobotForm.id !== "") {
                    let index = this.addRobotForm.id;
                    if (this.robotList[index].is_have === 0) {
                        this.addRobotForm.is_have = 0;
                        this.addRobotForm.name = this.robotList[index].name;
                        this.addRobotForm.good_id = this.robotList[index].good_id;
                    } else {
                        this.addRobotForm.is_have = 1;
                        this.addRobotForm.name = this.robotList[index].name;
                        this.addRobotForm.good_id = this.robotList[index].good_id;
                        this.addRobotForm.order_sn = this.robotList[index].order_sn;
                        this.addRobotForm.order_time = this.robotList[index].order_time;
                        this.addRobotForm.recipients = this.robotList[index].recipients;
                        this.addRobotForm.contact = this.robotList[index].contact;
                        this.addRobotForm.address = this.robotList[index].address;
                    }
                }
                // this.$refs.cursorInput.focus();
            },
            // 打开添加问题弹框
            openAddIssue() {
            },
            // 关闭添加机器人弹框
            addRobotResetForm() {
                this.$refs.addRobotForm.resetFields();
                this.addRobotForm.is_have = 0;
                this.addRobotForm.name = "";
                this.addRobotForm.good_id = "";
                this.addRobotForm.order_sn = "";
                this.addRobotForm.order_time = "";
                this.addRobotForm.recipients = "";
                this.addRobotForm.contact = "";
                this.addRobotForm.address = "";
                this.addRobotForm.id = "";
                this.addRobotForm.title = "";
            },
            // 关闭添加问题弹框
            issueResetForm() {
                this.$refs.addIssueForm.clearValidate();
                this.addIssueForm = {
                    index: "",
                    questionIndex: "",
                    type: 1,
                    name: "",
                    question: "",
                    answer: "",
                    score_operate: "",
                    score_operate_two: 1,
                    no_score_keyword: "",
                    analysis: "",
                    score: void 0,
                    coupon_id: "",
                    reason: "",
                    money: void 0,
                    good_id: "",
                    couponType_id: "",
                    price_man: void 0,
                    price_jian: void 0,
                    validity_time: "",
                };
                this.addIssueIndex = "";
            },
            //生成订单
            generateOrder() {
                getKeFuCreateSN()
                    .then((res) => {
                        this.$message.success(res.msg);
                        this.addRobotForm.order_sn = res.data;
                    })
                    .catch((err) => {
                    });
            },
            confirmForm() {
                if (this.robotList.length) {
                    for (let i = 0; i < this.robotList.length; i++) {
                        if (this.robotList[i].question.length < 1) {
                            this.$message.warning("每个机器人最少添加一个问题");
                            return;
                        }else{
                            // 校验机器人的数据是否合法
                            // 
                            // 是否已购买  是  否
                           let obj= JSON.parse(JSON.stringify(this.robotList[i]));
                             // 如果没有购买
                            if(obj.is_have==0){
                                delete obj.order_time
                                delete obj.order_sn
                                delete obj.recipients
                                delete obj.contact
                                delete obj.address
                            }
                            console.log(obj,"obj");
                            console.log('this.stepTwoGoods',this.stepTwoGoods);
                            
                            
                            // 先校验添加的机器人商品是否合法
                            let index=this.stepTwoGoods.findIndex((item)=>item.good_id==obj.good_id)
                            if(index==-1){
                                this.$message.warning(`第${i+1}个机器人咨询商品不存在`);
                                return false
                            }
                            const errors = this.validateData(obj, this.addRobotRules);
                            if(errors){
                                this.$message.warning(`第${i+1}个机器人${errors}`);
                                return false
                            }
                            // 校验机器人的问题是否合适
                            for (let j = 0; j < obj.question.length; j++) {
                                    // 问题的类型   问答题 和操作题
                                    // 操作题   购买和未购买
                                    // 操作题的类型  发送商品优惠券  申请直赔  发送商品   发送新建优惠券
                                    // 购买增加  发送核对订单
                                    if(this.selectCouponList.length==0){
                                        this.stepTwoGoods.forEach((item) => {
                                        if (item.good_id === this.robotList[i].good_id) {
                                            this.selectCouponList = item.coupon;
                                        }
                                    });
                                    }
                                
                                    let questionObj=JSON.parse(JSON.stringify(obj.question[j]));
                                    if(questionObj.type==2){
                                        if(questionObj.hasOwnProperty('no_score_keyword')) delete questionObj.no_score_keyword
                                        if(questionObj.score_operate==1){
                                            // 发送商品
                                            console.log('this.selectCouponList',this.selectCouponList);
                                            console.log('questionObj',questionObj);
                                            let index=this.selectCouponList.findIndex((item,index)=>index==questionObj.coupon_id)
                                            if(index==-1){
                                                this.$message.warning(`第${i+1}个机器人,第${j+1}个问题,优惠券不存在`);
                                                return false
                                            }
                                        }
                                        if(questionObj.score_operate==3){
                                            // 发送商品
                                            let index=this.stepTwoGoods.findIndex((item)=>item.good_id==questionObj.good_id)
                                            if(index==-1){
                                                this.$message.warning(`第${i+1}个机器人,第${j+1}个问题,咨询商品不存在`);
                                                return false
                                            }
                                        
                                        }
                                    }
                                   
                                  
                                    const errors = this.validateData(questionObj, this.addIssueRules);
                                    if(errors){
                                        this.$message.warning(`第${i+1}个机器人,第${j+1}个问题,${errors}`);
                                        return false
                                    }
                            }
                        }
                    }
                    let robot = JSON.parse(JSON.stringify(this.robotList));
                    for (let i = 0; i < robot.length; i++) {
                        delete robot[i].isShow;
                    }
                    this.$emit("stepActiveAdd", robot);
                } else {
                    this.$message.warning("请添加最少一个机器人");
                }
            },
            //全选
            handleCheckAllChange(val) {
                this.cityOptions = [];
                this.robotList.forEach((item, index) => {
                    this.cityOptions.push(index);
                });
                this.checkedCities = val ? this.cityOptions : [];
                this.isIndeterminate = false;
            },
            handleCheckedCitiesChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.cityOptions.length && checkedCount>0;
                this.isIndeterminate =
                    checkedCount > 0 && checkedCount < this.cityOptions.length;
            },
            validateData(data, rules) {  
                for (let key in rules) {
                    if (rules.hasOwnProperty(key) && data.hasOwnProperty(key)) {  
                       let value=data[key]
                       let rule=rules[key]
                        if(!value){

                            if(value===0 || key=="analysis"){
                                return null
                            }else{
                                return rule[0].message
                            }
                        }else{
                            // 有值的
                            // 校验是否合法
                            if(key=="order_sn"){
                                if (value.length !== 12) {
                                   
                                        return "订单编号的长度为12";
                                    } else {
                                        let numReg = /^[0-9]*$/;
                                        let numRe = new RegExp(numReg);
                                        if (!numRe.test(value)) {
                                      
                                            return "订单编号格式错误"
                                        }
                                }

                            }
                            if(key=="contact"){
                                let reg = /^1[345789]\d{9}$/;
                                    if (!reg.test(value)) {
                                        return "联系方式格式有误"
                                    }
                            }
                            if(key=="order_time"){
                                const reg = /^\d{4}-\d{2}-\d{2}$/;
                                const reg2=/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
                                    if (!reg.test(value) && !reg2.test(value)) {
                            
                                        return "下单时间格式有误"
                                    }
                            }
                        }

    
                    }else if(key=='score'){
                        if(!data.hasOwnProperty(key)){
                            return "请设置分数"
                        }
                    }else if(key=="money"){
                        if(data.hasOwnProperty('reason')){
                            if(!data.hasOwnProperty(key)){
                            return "请设置直赔金额"
                        }
                        }
                    }else if(key=="price_man" || key=="price_jian"){
                        if(data.hasOwnProperty("couponType_id")){
                            if(!data.hasOwnProperty(key) || !data.hasOwnProperty("price_jian")){
                                return "请设置满减金额"
                            }else{
                                if(data["price_man"]<data["price_jian"]){
                                    return "减额不能大于满额"
                                }
                            }
                        }
                    }
                }
            
            }
            
        },
    };
</script>

<style scoped lang="scss">
    .set-service-data {
        margin-top: 20px;

        .service-data-btn {
            display: flex;
            justify-content: space-between;
        }

        .service-data-table {
            border: 1px solid #eeeeee;
            box-sizing: border-box;
            width: 100%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            .data-table-header {
                padding: 0 20px 0 20px;
                display: flex;
                background: #eeeeee;
                height: 60px;
                line-height: 60px;
                box-sizing: border-box;

                span {
                    color: #666666;
                    font-size: 16px;
                }

                .table-header-item {
                    flex: 1;
                    width: 1%;
                    box-sizing: border-box;

                    &:nth-child(2) {
                        span {
                            margin-left: 16px;
                        }
                    }

                    &:nth-child(3),
                    &:nth-child(6),
                    &:last-of-type {
                        text-align: center;
                    }

                    &:nth-child(5) {
                        span {
                            margin-left: 40px;
                        }
                    }
                }
            }

            .data-table-body {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;

                .table-body-item {
                    display: flex;
                    flex-direction: column;

                    .body-item-top,
                    .body-item-bottom {
                        padding: 0 20px 0 20px;
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid #eeeeee;

                        .item-top-item,
                        .item-bottom-item {
                            width: 1%;
                            flex: 1;
                            height: 70px;
                            display: flex;
                            align-items: center;

                            &:nth-child(2) {
                                span {
                                    margin-left: 16px;
                                }
                            }

                            i {
                                line-height: 1;
                                cursor: pointer;
                                font-size: 18px;
                                color: #7d7d7d;
                                margin-right: 4px;
                            }

                            span {
                                //flex: 1;
                                font-size: 16px;
                                color: #333;
                            }

                            &:nth-child(3),
                            &:nth-child(6),
                            &:last-of-type {
                                justify-content: center;
                            }

                            &:nth-child(5) {
                                span {
                                    margin-left: 40px;
                                }
                            }
                        }
                    }
                }
            }

            .table-noData {
                height: 500px;
                line-height: 500px;
                text-align: center;
                color: #666666;
                font-size: 16px;
            }
        }
    }

    ::v-deep.el-checkbox {
        .el-checkbox__label {
            display: none;
        }
    }

    ::v-deep .green-dialog2 {
        .el-dialog__header {
            padding: 16px 24px;
            background: #f3f6f8;

            span,
            i {
                font-size: 16px;
                line-height: 1;
                color: #333333;
            }
        }

        .el-dialog__body {
            padding: 22px 24px 22px 0px;
            // 带表格的
            .el-form-item {
                margin-bottom: 24px;
            }

            .el-form-item__label {
                font-size: 16px;
                color: #666666;
                //margin-bottom: 10px;
            }

            .dialog-footer {
                margin-top: 30px;
                text-align: center;
            }
        }
    }

    ::v-deep .el-link + .el-link {
        margin-left: 22px;

        &:before {
            content: "";
            position: absolute;
            left: -12px;
            width: 2px;
            height: 16px;
            background: #dfe9ff;
        }
    }
</style>
