<template>
    <div class="paper-manage-table">
        <el-scrollbar style="height: 100%">
            <el-table
                border
                :data="tableData"
                style="width: 100%; height: 100%"
                header-row-class-name="table-header-style"
                header-cell-class-name="table-header-style"
            >
                <el-table-column prop="paper_name" label="试卷名称"> </el-table-column>
                <el-table-column prop="category_name" label="题库分类"> </el-table-column>
                <el-table-column prop="paperType" label="组卷类型" width="200"></el-table-column>
                <el-table-column prop="questionCount" label="试题数量" width="200"></el-table-column>
                <el-table-column prop="operate" label="操作" width="250" align="center">
                    <template slot-scope="item">
                        <div class="operate-options">
                            <div class="detail item" v-if="item.row.type != 1" @click="exportExamination(item.row)">导出</div>
                            <div class="detail item" v-if="item.row.type != 1" @click="toDetail(item.row)">查看详情</div>
                            <div class="edit item" @click="editPaper(item.row)">编辑</div>
                            <div class="delete item" @click="deletePaper(item.row)">删除</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>
    </div>
</template>

<script>

export default {
    name: "PaperTable",
    data() {
        return {};
    },
    props: {
        tableData: {
            type: Array,
            default() {
                return [];
            },
        },
        deletePaper: {
            type: Function,
            default() {
                return function() {}
            }
        }
    },
    components: {},
    methods: {
        /**详情 */
        toDetail(question) {
            this.$router.push({
                path: "/admin/paperDetail",
                query: {
                    id: question.paper_id
                }
            })
        },
        editPaper(question) {
            let routers = ["", "/admin/randomPaper", "/admin/artificialPaper"];
            this.$router.push({
                path: routers[question.type],
                query: {
                    id: question.paper_id
                }
            })
        },
        exportExamination(row){
              window.location.href=`theoryPaper/export?paper_id=${row.paper_id}&token=${localStorage.getItem("token")}`
        }
    },
};
</script>

<style scoped lang="scss">
.el-table ::v-deep .table-header-style {
    background-color: #f2f3fa !important;
    font-size: 14px;
    color: #13131b;
}

.el-table ::v-deep .el-table__row .cell {
    padding-top: 12px;
    padding-bottom: 12px;
}

.paper-manage-table {
    flex: 1;
    margin: 12px 0;
    font-size: 14px;
    overflow: hidden;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .operate-options {
        display: flex;
        justify-content: center;
        color: #1122d8;
        .item {
            margin-right: 20px;
            cursor: pointer;
        }
        .delete {
            color: #eb6547;
        }
    }
}
</style>
