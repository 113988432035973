import Vue from "vue";
import VueRouter from "vue-router";
import adminLayout from "../layout/admin/Index";
import adminDecoration from "../layout/admin/Decoration";
import schoolAdminLayout from "../layout/schoolAdmin/Index";
import adminOperation from "../layout/admin/Operation";
import trainAdminLayout from "../layout/trainAdmin/Index";
import adminCompetition from "../layout/admin/Competition";
import studentStoreDecorate from "../layout/student/storeDecorate/Index";
import studentOperationPromotion from "../layout/student/dataOperation/Index";
import commodityListLayout from "../layout/student/dataOperation/CommodityListLayout";
import studentCreatePlanLayout from "../layout/student/dataOperation/studentCreatePlanLayout";
import teacherLayout from "../layout/teacher/Index";
import goodManageLayout from "../layout/student/dataOperation/GoodManageLayout";
import CommodityPurchaseLayout from "../layout/student/dataOperation/CommodityPurchaseLayout";
import studentTrainManage from "../layout/student/trainManage/Index";
import TrainLayout from "../layout/student/trainManage/TrainLayout";
import studentSchoolAdminDecorate from "../layout/student/storeDecorate/SchoolAdminLayout";
import dataAnalysisLayout from "../layout/student/shopSelection/dataAnalysisLayout";
import theoryLayout from "@/layout/admin/theoryLayout";
import DataCenterLayout from "../layout/trainAdmin/DataCenterLayout.vue";
import TheoryManageLayout from "../layout/trainAdmin/TheoryManageLayout";
import Analysis from '../layout/admin/Analysis'
import adminNewKeFu from "../layout/admin/NewKeFu";
Vue.use(VueRouter);
const website = localStorage.getItem('web') ? localStorage.getItem('web') + '--' : "";
const routes = [
    {
        path: "/admin",
        redirect: "/admin/index",
        component: adminLayout,
        children: [
            {
                path: "/admin/datacenter",
                name: "dataCenter",
                component: () => import("../views/admin/dataCenter.vue"),
                meta: {
                    title: "数据中心",
                },
            },
            {
                path: "/admin/index",
                name: "adminIndex",
                component: () => import("../views/admin/Index.vue"),
                meta: {
                    title: "教务管理",
                },
            },
            {
                path: "/admin/competition",
                name: "competition",
                component: adminCompetition,
                children: [
                    {
                        path: "/admin/competition",
                        name: "competitionData",
                        component: () => import("../views/admin/Competition"),
                        meta: {
                            title: "竞赛模板管理",
                        },
                    },
                    {
                        path: "/admin/createcompetition",
                        name: "createCompetition",
                        component: () =>
                            import(
                                "../components/admin/competitionManage/CreateCompetition"
                            ),
                        meta: {
                            title: "创建竞赛模板",
                        },
                    },
                ],
            },
            {
                path: "/admin/datacenterdetail",
                name: "dataCenterDetail",
                component: () => import("../views/admin/dataCenterDetail.vue"),
                meta: {
                    title: "数据中心",
                },
            },
            {
                path: "/admin/operatedata/index",
                name: "operateManage",
                component: adminOperation,
                children: [
                    {
                        path: "/admin/operatedata/index",
                        name: "operateData",
                        component: () => import("../views/admin/OperateData"),
                        meta: {
                            title: "运营管理",
                            tabName: "operateData",
                        },
                    },
                    {
                        path: "/admin/operatedata/operationmanage/create",
                        name: "operateDataOperationManageCreate",
                        component: () =>
                            import(
                                "../components/admin/operationManage/CreateOperationModule"
                            ),
                        meta: {
                            title: "创建运营模板",
                            tabName: "operateData",
                        },
                    },
                    {
                        path: "/admin/operatedata/storeselection",
                        name: "operateDataStoreSelection",
                        component: () =>
                            import(
                                "../components/admin/operationManage/StoreSelection.vue"
                            ),
                        meta: {
                            title: "店铺选品",
                            tabName: "operateDataStoreSelection",
                        },
                    },
                    {
                        path: "/admin/operatedata/storeselection/create",
                        name: "operateDataStoreSelectionCreate",
                        component: () =>
                            import(
                                "../components/admin/operationManage/CreateStoreSelection.vue"
                            ),
                        meta: {
                            title: "创建店铺选品",
                            tabName: "operateDataStoreSelection",
                        },
                    },
                    {
                        path: "/admin/operatedata/storemarket",
                        name: "operateDataStoreMarket",
                        component: () =>
                            import(
                                "../components/admin/operationManage/StoreMarket.vue"
                            ),
                        meta: {
                            title: "店铺营销",
                            tabName: "operateDataStoreMarket",
                        },
                    },
                    {
                        path: "/admin/operatedata/searchads",
                        name: "operateDataSearchAds",
                        component: () =>
                            import("../components/admin/operationManage/SearchAds.vue"),
                        meta: {
                            title: "搜索广告",
                            tabName: "operateDataSearchAds",
                        },
                    },
                    {
                        path: "/admin/operatedata/searchads/create",
                        name: "operateDataSearchAdsCreate",
                        component: () =>
                            import(
                                "../components/admin/operationManage/CreateSearchAds"
                            ),
                        meta: {
                            title: "创建搜索广告",
                            tabName: "operateDataSearchAds",
                        },
                    },
                    {
                        path: "/admin/operatedata/hotkeywords",
                        name: "operateDataHotKeywords",
                        component: () =>
                            import(
                                "../components/admin/operationManage/HotKeywords.vue"
                            ),
                        meta: {
                            title: "热门关键词",
                            tabName: "operateDataHotKeywords",
                        },
                    },
                    {
                        path: "/admin/operatedata/hotkeywords/create",
                        name: "operateDataHotKeywordsCreate",
                        component: () =>
                            import(
                                "../components/admin/operationManage/CreateHotKeywords"
                            ),
                        meta: {
                            title: "创建热门关键词",
                            tabName: "operateDataHotKeywords",
                        },
                    },
                    {
                        path: "/admin/operatedata/goodsinfo",
                        name: "operateDataGoodsInfo",
                        component: () =>
                            import("../components/admin/operationManage/GoodsInfo"),
                        meta: {
                            title: "搜索广告商品",
                            tabName: "operateDataGoodsInfo",
                        },
                    },
                    {
                        path: "/admin/operatedata/goodsinfo/create",
                        name: "operateDataGoodsInfoCreate",
                        component: () =>
                            import(
                                "../components/admin/operationManage/CreateGoodsInfo"
                            ),
                        meta: {
                            title: "创建搜索广告商品",
                            tabName: "operateDataGoodsInfo",
                        },
                    },
                    {
                        path: "/admin/operatedata/goodsattr",
                        name: "operateDataGoodsAttr",
                        component: () =>
                            import("../components/admin/operationManage/GoodsAttr.vue"),
                        meta: {
                            title: "商品信息",
                            tabName: "operateDataGoodsAttr",
                        },
                    },
                    {
                        path: "/admin/operatedata/goodsattr/create",
                        name: "operateDataGoodsAttrCreate",
                        component: () =>
                            import(
                                "../components/admin/operationManage/CreateGoodsAttr"
                            ),
                        meta: {
                            title: "创建商品信息",
                            tabName: "operateDataGoodsAttr",
                        },
                    },
                    {
                        path: "/admin/operatedata/boothadvertising",
                        name: "operateDataBoothAdvertising",
                        component: () =>
                            import(
                                "../components/admin/operationManage/BoothAdvertising.vue"
                            ),
                        meta: {
                            title: "展位广告",
                            tabName: "operateDataBoothAdvertising",
                        },
                    },
                    {
                        path: "/admin/operatedata/boothadvertisingadd",
                        name: "operateDataBoothAdvertisingAdd",
                        redirect: "/admin/operatedata/boothadvertisingtwo",
                        component: () =>
                            import("../views/admin/BoothAdvertisingAdd"),
                        children: [
                            {
                                path: "/admin/operatedata/boothadvertisingtwo",
                                name: "operateDataBoothAdvertisingTwo",
                                component: () =>
                                    import(
                                        "../components/admin/BoothAdvertising/BoothAdvertisingTwo.vue"
                                    ),
                                meta: {
                                    title: "添加展位广告",
                                },
                            },
                        ],
                        meta: {
                            title: "创建展位广告",
                        },
                    },
                    {
                        path: "/admin/operatedata/storemarketingadd",
                        name: "operateDataStoreMarketingAdd",
                        redirect: "/admin/operatedata/storemarketingone",
                        component: () => import("../views/admin/StoreMarketingAdd.vue"),
                        children: [
                            {
                                path: "/admin/operatedata/storemarketingone",
                                name: "operateDataStoreMarketingOne",
                                component: () =>
                                    import(
                                        "../components/admin/storeMarketStep/StoreMarketingOne.vue"
                                    ),
                                meta: {
                                    title: "创建店铺营销",
                                    tabName: "operateDataStoreMarket",
                                },
                            },
                            {
                                path: "/admin/operatedata/storemarketingtwo",
                                name: "operateDataStoreMarketingTwo",
                                component: () =>
                                    import(
                                        "../components/admin/storeMarketStep/StoreMarketingTwo.vue"
                                    ),
                                meta: {
                                    title: "创建店铺营销",
                                    tabName: "operateDataStoreMarket",
                                },
                            },
                            {
                                path: "/admin/operatedata/storemarketingthree",
                                name: "operateDataStoreMarketingThree",
                                component: () =>
                                    import(
                                        "../components/admin/storeMarketStep/StoreMarketingThree.vue"
                                    ),
                                meta: {
                                    title: "创建店铺营销",
                                    tabName: "operateDataStoreMarket",
                                },
                            },
                            {
                                path: "/admin/operatedata/storemarketingsucceed",
                                name: "operateDataStoreMarketingSucceed",
                                component: () =>
                                    import(
                                        "../components/admin/storeMarketStep/StoreMarketingSucceed"
                                    ),
                                meta: {
                                    title: "创建成功",
                                    tabName: "operateDataStoreMarket",
                                },
                            },
                        ],
                        meta: {
                            title: "创建店铺营销",
                            tabName: "operateDataStoreMarket",
                        },
                    },
                ],
            },
            {
                path: "/admin/storecustomer",
                name: "storeCustomer",
                component: () => import("../views/admin/StoreCustomer"),
                meta: {
                    title: "店铺客服管理",
                },
            },
            {
                path: "/admin/storecustomer/servicedatacreation",
                name: "serviceDataCreation",
                redirect: "createStepOne",
                component: () => import("../views/admin/serviceDataCreation"),
                children: [
                    {
                        path: "/admin/storecustomer/createstepone",
                        name: "createStepOne",
                        component: () =>
                            import(
                                "../components/admin/shopServiceAdmin/createStepOne.vue"
                            ),
                    },
                    {
                        path: "/admin/storecustomer/createsteptwo",
                        name: "createStepTwo",
                        component: () =>
                            import(
                                "../components/admin/shopServiceAdmin/createStepTwo.vue"
                            ),
                    },
                    {
                        path: "/admin/storecustomer/createstepsucceed",
                        name: "createStepSucceed",
                        component: () =>
                            import(
                                "../components/admin/shopServiceAdmin/createStepSucceed"
                            ),
                    },
                ],
                meta: {
                    title: "创建客服数据",
                },
            },
            {
                path: "/admin/storecustomer/servicecmmoditycreation",
                name: "serviceCmmodityCreation",
                component: () => import("../views/admin/serviceCmmodityCreation"),
            },
            {
                path: "/admin/decorationdata",
                name: "decorationManage",
                component: adminDecoration,
                children: [
                    {
                        path: "/admin/decorationdata",
                        name: "decorationData",
                        component: () => import("../views/admin/DecorationData.vue"),
                        meta: {
                            title: "店铺装修",
                        },
                    },
                    {
                        path: "/admin/createdecoration",
                        name: "createDecoration",
                        component: () =>
                            import(
                                "../components/admin/storeDecorationManage/CreateDecoration"
                            ),
                        meta: {
                            title: "创建装修数据",
                            parentName: 'decorationData',
                        },
                    },
                    {
                        path: "/admin/registerData",
                        name: "adminRegisterData",
                        component: () => import("../views/admin/storeRegister/Index"),
                        meta: {
                            title: "店铺注册",
                        },
                    },
                    {
                        path: "/admin/registerCreate",
                        name: "adminRegisterCreate",
                        component: () => import("../views/admin/storeRegister/Create"),
                        meta: {
                            title: "创建店铺注册",
                            parentName: 'adminRegisterData',
                        },
                    },
                ],
            },
            {
                path: "/admin/rolepermissions",
                name: "rolePermissions",
                component: () => import("../views/admin/RolePermissions"),
                meta: {
                    title: "角色权限管理",
                },
            },
            {
                path: "/admin/aliossmanager",
                name: "aliossManager",
                component: () => import("../views/admin/AliOssManager"),
                meta: {
                    title: "阿里云oss管理",
                },
            },
            {
                path: "/admin/web",
                name: "webSetting",
                component: () => import("../views/admin/WebSetting"),
                meta: {
                    title: "网站应用设置",
                },
            },
            {
                name: "theoryManage",
                path: "/admin/theorymanage",
                component: theoryLayout,
                children: [
                    {
                        path: "/admin/theorymanage",
                        name: "topicManage",
                        component: () => import("../views/admin/theoryManage"),
                        meta: {
                            title: "试题管理",
                        },
                    },
                    {
                        path: "/admin/paperManage",
                        name: "PaperManage",
                        component: () =>
                            import("../views/admin/paperManage/PaperManage.vue"),
                        meta: {
                            title: "试卷管理",
                        },
                    },
                    {
                        path: "/admin/topicLibrary",
                        name: "topicLibrary",
                        component: () => import("../views/admin/theoryLibaray"),
                        meta: {
                            title: "题库管理",
                        },
                    },
                    {
                        path: "/admin/topic/detail",
                        name: "theoryTopicDetail",
                        component: () =>
                            import("../views/admin/theoryTopic/theoryTopicDetail"),
                        meta: {
                            title: "试题创建/编辑",
                        },
                    },
                    {
                        path: "/admin/artificialPaper",
                        name: "ArtificialPaper",
                        component: () =>
                            import("../views/admin/paperManage/ArtificialPaper"),
                        meta: {
                            title: "择题组卷",
                        },
                    },
                    {
                        path: "/admin/randomPaper",
                        name: "RandomPaper",
                        component: () =>
                            import("../views/admin/paperManage/RandomPaper"),
                        meta: {
                            title: "随机组卷",
                        },
                    },
                    {
                        path: "/admin/paperDetail",
                        name: "PaperDetail",
                        component: () =>
                            import("../views/admin/paperManage/PaperDetail"),
                        meta: {
                            title: "试卷详情",
                        },
                    },
                ],
            },
            {
                path: "/admin/dataAnalysis",
                name: "dataAnalysis",
                component: Analysis,
                children: [{
                    path: '/admin/dataAnalysis/manager',
                    name: 'dataAnalysisManager',
                    component: () =>
                        import ("../views/admin/dataAnalysis/DataAnalysisManager.vue"),
                    meta: {
                        title: '数据管理',
                        tabName: "dataAnalysisManager",
                    }
                },{
                    path: '/admin/dataAnalysis/create',
                    name: 'createAnalysis',
                    component: () =>
                        import ("../views/admin/dataAnalysis/CreateDataAnalysis.vue"),
                    meta: {
                        title: '创建数据管理',
                        tabName: "dataAnalysisManager",
                    }
                },{
                    path: '/admin/dataTemplateManager',
                    name: 'dataTemplateManager',
                    component: () =>
                        import ("../views/admin/dataTemplateManager.vue"),
                    meta: {
                        title: '数据模板管理',
                        tabName: "dataTemplateManager",
                    }
                },{
                    path: '/admin/dataTemplateManager/create',
                    name: 'dataTemplateCreate',
                    component: () =>
                        import ("../components/admin//createTemplate/createTemplate.vue"),
                    meta: {
                        title: '创建数据模板',
                        tabName: "dataTemplateManager",
                    }
                }],
                meta: {
                    title: "数据分析",
                },
            },
            {
            path: "/admin/newstorecustomer",
            name: "newkefuManage",
            component: adminNewKeFu,
            children:[
                {
                    path: "/admin/newstorecustomer",
                    name: "newstoreCustomerData",
                    component: () => import("../views/admin/NewStoreCustomerData.vue"),
                    meta: {
                        title: "店铺客服管理",
                    },
                },
                {
                    path: "/admin/creatnewstorecustomer",
                    name: "CreatNewStoreCustomer",
                    component: () =>
                        import(
                            "../components/admin/creatNewKeFuManage/CreatNewStoreCustomer.vue"
                        ),
                    meta: {
                        title: "创建客服数据",
                    },  
                }
            ]
        }
        ],
    },
    {
        path: "/admin/store/index",
        name: "adminStoreIndex",
        component: () => import("../views/admin/studentStoreDetail/Index"),
        meta: {
            title: "店铺首页",
        },
    },
    {
        path: "/admin/store/gooddetail",
        name: "adminStoreGoodDetail",
        component: () => import("../views/student/StoreDetail/GoodDetail"),
        meta: {
            title: "商品详情页",
        },
    },
    {
        path: "/schooladmin",
        redirect: "/schooladmin/index",
        component: schoolAdminLayout,
        children: [
            {
                path: "/schooladmin/datacenter",
                name: "schoolAdminDataCenter",
                component: () => import("../views/admin/dataCenter.vue"),
                meta: {
                    title: "数据中心",
                },
            },
            {
                path: "/schooladmin/datacenterdetail",
                name: "schooldataCenterDetail",
                component: () => import("../views/admin/dataCenterDetail.vue"),
                meta: {
                    title: "榜单详情",
                },
            },
            {
                path: "/schooladmin/index",
                name: "schoolAdminIndex",
                component: () => import("../views/schoolAdmin/Index"),
                meta: {
                    title: "教务管理",
                },
            },
            {
                path: "/schooladmin/competition",
                name: "schoolAdminCompetition",
                component: () => import("../views/schoolAdmin/Competition"),
                meta: {
                    title: "竞赛管理",
                },
            },
            {
                path: "/schooladmin/competition/creationcompetition",
                name: "creationCompetition",
                redirect: "/schooladmin/competition/creationcompetitionone",
                component: () => import("../views/schoolAdmin/creationCompetition"),
                children: [
                    {
                        path: "/schooladmin/competition/creationcompetitionone",
                        name: "creationCompetitionOne",
                        component: () =>
                            import(
                                "../components/schoolAdmin/creationCompetitionOne"
                            ),
                    },
                    {
                        path: "/schooladmin/competition/creationcompetitiontwo",
                        name: "creationCompetitionTwo",
                        component: () =>
                            import(
                                "../components/schoolAdmin/creationCompetitionTwo.vue"
                            ),
                    },
                    {
                        path: "/schooladmin/competition/creationCompetitionthree",
                        name: "creationCompetitionThree",
                        component: () =>
                            import(
                                "../components/schoolAdmin/creationCompetitionThree"
                            ),
                    },
                    {
                        path: "/schooladmin/competition/creationCompetitionsucceed",
                        name: "creationCompetitionSucceed",
                        component: () =>
                            import(
                                "../components/schoolAdmin/creationCompetitionSucceed"
                            ),
                    },
                ],
                meta: {
                    title: "创建竞赛",
                },
            },
        ],
    },
    {
        path: '/student/theorycompetition',
        name: "stuTheoryCompetition",
        component: () => import("../views/student/studentTheory/studentTheoryCompetition")
    },
    {
        path: "/trainadmin",
        redirect: "/trainadmin/index",
        component: trainAdminLayout,
        children: [
            {
                path: "/trainadmin/datacenter",
                name: "trainAdminDataCenter",
                component: DataCenterLayout,
                redirect: "/trainadmin/datacenter/scoreList",
                children: [
                    {
                        path: "/trainadmin/datacenter/scoreList",
                        name: "DataCenterScoreList",
                        component: () =>
                            import("../components/admin/DataCenterScoreList.vue"),
                        meta: {
                            title: "得分榜单",
                        },
                    },
                    {
                        path: "/trainadmin/datacenter/theoryList",
                        name: "DataCenterTheoryList",
                        component: () =>
                            import("../components/schoolAdmin/TheoryList.vue"),
                        meta: {
                            title: "理论榜单",
                            tab: 1,
                        },
                    },
                    {
                        path: "/trainadmin/datacenter/studenttheoryList",
                        name: "DataCenterStudentTheoryList",
                        component: () =>
                            import("../components/schoolAdmin/StudentTheoryList"),
                        meta: {
                            title: "班级详情",
                            tab: 1,
                        },
                    },
                    {
                        path: "/trainadmin/datacenter/studenttheorydetail",
                        name: "DataCenterStudentTheoryDetail",
                        component: () =>
                            import("../components/schoolAdmin/StudentTheoryDetail"),
                        meta: {
                            title: "训练详情",
                            tab: 1,
                        },
                    },
                    {
                        path: "/trainadmin/datacenter/studentanswesr",
                        name: "DataCenterStudentAnswer",
                        component: () =>
                            import("../components/schoolAdmin/StudentAnswer"),
                        meta: {
                            title: "答题详情",
                            tab: 1,
                        },
                    },
                ],
                meta: {
                    title: "数据中心",
                },
            },
            {
                path: "/trainadmin/datacenterdetail",
                name: "trainaDataCenterDetail",
                component: () => import("../views/admin/dataCenterDetail"),
                meta: {
                    title: "榜单详情",
                },
            },
            {
                path: "/trainadmin/index",
                name: "trainAdminIndex",
                component: () => import("../views/schoolAdmin/Index"),
                meta: {
                    title: "教务管理",
                },
            },
            {
                path: "/trainadmin/trainmanage",
                name: "trainAdminTrainManage",
                component: () => import("../views/trainAdmin/TrainManage"),
                meta: {
                    title: "训练管理",
                },
            },
            {
                path: "/trainAdmin/theoryList",
                name: "TheoryList",
                component: () => import("../components/schoolAdmin/TheoryList"),
            },
            {
                path: "/trainadmin/trainmanage/creationcompetition",
                name: "trainCreationCompetition",
                redirect: "/trainadmin/trainmanage/trainadmincreationcompetitionone",
                component: () =>
                    import("../views/trainAdmin/trainCreationCompetition"),
                children: [
                    {
                        path: "/trainadmin/trainmanage/trainadmincreationcompetitionone",
                        name: "trainAdminCreationCompetitionOne",
                        component: () =>
                            import(
                                "../components/trainAdmin/trainAdminData/trainAdminCreationCompetitionOne"
                            ),
                        meta: {
                            title: "创建训练",
                        },
                    },
                    {
                        path: "/trainadmin/trainmanage/trainadmincreationcompetitiontwo",
                        name: "trainAdminCreationCompetitionTwo",
                        component: () =>
                            import(
                                "../components/trainAdmin/trainAdminData/trainAdminCreationCompetitionTwo.vue"
                            ),
                        meta: {
                            title: "选择训练内容",
                        },
                    },
                    {
                        path: "/trainadmin/trainmanage/trainadmincreationcompetitionsucceed",
                        name: "trainAdminCreationCompetitionSucceed",
                        component: () =>
                            import(
                                "../components/trainAdmin/trainAdminData/trainAdminCreationCompetitionSucceed"
                            ),
                        meta: {
                            title: "操作成功",
                        },
                    },
                ],
                meta: {
                    title: "创建训练",
                },
            },
            {
                path: "/trainadmin/decorationscore",
                name: "trainAdminDecorationScore",
                component: () => import("../views/trainAdmin/DecorationScore"),
                meta: {
                    title: "装修得分",
                },
            },
            {
                path: "/trainAdmin/theoryManage",
                name: "trainAdminTheoryManage",
                component: TheoryManageLayout,
                redirect: "/trainAdmin/theoryManage/index",
                children: [
                    {
                        path: "/trainAdmin/theoryManage/index",
                        name: "trainAdminTheoryManageIndex",
                        component: () =>
                            import("../components/schoolAdmin/theoryManagement"),
                        meta: {
                            title: "理论考试管理",
                        },
                    },
                    {
                        path: "/trainAdmin/theoryManage/creationTheory",
                        name: "trainAdminCreationTheory",
                        component: () =>
                            import("../components/schoolAdmin/creationTheory"),
                        meta: {
                            title: "创建理论训练",
                        },
                    },
                    {
                        path: "/trainAdmin/problemAnalysis/index",
                        name: "trainAdminProblemAnalysis",
                        component: () =>
                            import("../components/schoolAdmin/problemAnalysis"),
                        meta: {
                            title: "问答评分",
                            tab: 1,
                        },
                    },
                    {
                        path: "/trainAdmin/problemAnalysis/problemStudentDetail",
                        name: "trainAdminProblemStudentDetail",
                        component: () =>
                            import("../components/schoolAdmin/problemStudentDetail"),
                        meta: {
                            title: "问答评分-学生列表",
                            tab: 1,
                        },
                    },
                    {
                        path: "/trainAdmin/problemAnalysis/problemStudentAnswerList",
                        name: "trainAdminProblemStudentAnswerList",
                        component: () =>
                            import(
                                "../components/schoolAdmin/problemStudentAnswerList"
                            ),
                        meta: {
                            title: "问答评分-学生详情",
                            tab: 1,
                        },
                    },
                ],
                meta: {
                    title: "理论考试管理",
                },
            },
        ],
    },
    {
        path: "/",
        name: "login",
        component: () => import("../views/Login.vue"),
        meta: {
            title: website + '欢迎登录'
        }
    },
    {
        path: "/student/index",
        name: "studentIndex",
        component: () => import("../views/student/Index.vue"),
        meta: {
            title: website + '模块选择'
        }
    },
    {
        path: "/student/competition/index",
        name: "studentCompetitionIndex",
        component: () => import("../views/student/CompetitionIndex.vue"),
        meta: {
            title: website + '竞赛模块选择'
        }
    },
    {
        path: "/student/train/index",
        name: "studentTrainIndex",
        component: () => import("../views/student/TrainIndex.vue"),
        meta: {
            title: website + '理论模块选择'
        }
    },
    {
        path: "/student/train/studenttrainlist",
        name: "StudentTrainList",
        component: () => import("../views/student/trainManage/StudentTrainList.vue"),
        meta: {
            title: "训练管理列表",
        },
    },
    {
        path: "/student/train/studentAnswerDetail",
        name: "StudentAnswerDetail",
        component: () => import("../views/student/StudentAnswerDetail.vue"),
        meta: {
            title: "训练详情",
        },
    },
    {
        path: "/student/storedecorate",
        redirect: "/student/storedecorate/index",
        component: studentStoreDecorate,
        children: [
            {
                path: "/student/storedecorate/index",
                name: "storeDecorateIndex",
                component: () => import("../views/student/storeDecorate/Index"),
                meta: {
                    title: "店铺装修-店招",
                },
            },
            {
                path: "/student/storedecorate/computerposter",
                name: "storeDecorateComputerPoster",
                component: () =>
                    import("../views/student/storeDecorate/ComputerPoster"),
                meta: {
                    title: "店铺装修-电脑海报",
                },
            },
            {
                path: "/student/storedecorate/storegoods",
                name: "storeDecorateStoreGoods",
                component: () => import("../views/student/storeDecorate/StoreGoods"),
                meta: {
                    title: "店铺装修-店铺商品",
                },
            },
            {
                path: "/student/storedecorate/storegoodsAdd",
                name: "storeDecorateStoreGoodsAdd",
                component: () =>
                    import("../views/student/storeDecorate/StoreGoodsAdd"),
                meta: {
                    title: "店铺商品-发布商品",
                },
            },
            {
                path: "/student/storedecorate/storecategory",
                name: "storeDecorateStoreCategory",
                component: () =>
                    import("../views/student/storeDecorate/StoreCategory"),
                meta: {
                    title: "店铺装修-店铺分类",
                },
            },
        ],
    },
    {
        name: "shopProduct",
        path: "/student/operationpromotion",
        redirect: "/student/operationpromotion/index",
        component: studentOperationPromotion,
        children: [
            {
                path: "/student/operationpromotion/index",
                name: "operationPromotionManage",
                component: CommodityPurchaseLayout,
                children: [
                    {
                        path: "/student/operationpromotion/index",
                        name: "operationPromotionIndex",
                        component: () =>
                            import(
                                "../views/student/dataOperation/shopSelection/Index"
                            ),
                        meta: {
                            title: "商品采购",
                        },
                    },
                    {
                        path: "/student/operationpromotion/purchasedetail",
                        name: "operationPromotionPurchaseDetail",
                        component: () =>
                            import(
                                "../views/student/dataOperation/shopSelection/PurchaseDetail"
                            ),
                        meta: {
                            title: "商品采购——商品详情",
                        },
                    },
                ],
            },
            {
                path: "/student/operationpromotion/goodsmanage",
                name: "operationPromotionGoodsManage",
                component: goodManageLayout,
                children: [
                    {
                        path: "/student/operationpromotion/goodsmanage",
                        name: "operationPromotionGoodsManageIndex",
                        component: () =>
                            import(
                                "../views/student/dataOperation/shopSelection/GoodsManage"
                            ),
                        meta: {
                            title: "商品管理",
                        },
                    },
                    {
                        path: "/student/operationpromotion/goodsmanage/detail",
                        name: "operationPromotionGoodsManageDetail",
                        component: () =>
                            import(
                                "../views/student/dataOperation/shopSelection/GoodManageDetail"
                            ),
                        meta: {
                            title: "商品管理——商品详情",
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/student/operationpromotion/industrymarket",
        name: "promotionIndustryMarket",
        component: dataAnalysisLayout,
        children: [
            {
                path: "/student/operationpromotion/industrymarket",
                name: "operationPromotionIndustryMarket",
                component: () =>
                    import(
                        "../views/student/dataOperation/shopSelection/IndustryMarket"
                    ),
                meta: {
                    title: "行业数据",
                },
            },
            {
                path: "/student/operationpromotion/commoditylist",
                name: "operationPromotionCommodityList",
                component: commodityListLayout,
                children: [
                    {
                        path: "/student/operationpromotion/commoditylist",
                        name: "operationPromotionCommodityListIndex",
                        component: () =>
                            import(
                                "../views/student/dataOperation/shopSelection/CommodityList"
                            ),
                        meta: {
                            title: "商品榜单",
                        },
                    },
                    {
                        path: "/student/operationpromotion/commoditylist/treaddetail",
                        name: "operationPromotionCommodityListTreadDetail",
                        component: () =>
                            import(
                                "../views/student/dataOperation/shopSelection/TreadDetail"
                            ),
                        meta: {
                            title: "商品榜单——商品详情",
                        },
                    },
                    {
                        path: "/student/operationpromotion/commoditylist/brandDetail",
                        name: "operationPromotionCommodityListBrandDetail",
                        component: () =>
                            import(
                                "../views/student/dataOperation/shopSelection/BrandDetail"
                            ),
                        meta: {
                            title: "商品榜单——品牌详情",
                        },
                    },
                ],
            },
            {
                path: "/student/operationpromotion/HotKeywords",
                name: "operationPromotionHotKeywords",
                component: () =>
                    import(
                        "../views/student/dataOperation/shopSelection/HotKeywords"
                    ),
                meta: {
                    title: "热门关键词",
                },
            },
        ],
    },
    {
        path: "/student/operationpromotion/HotKeywords",
        name: "operationPromotionHotKeywords",
        component: () =>
            import("../views/student/dataOperation/shopSelection/HotKeywords"),
        meta: {
            title: "热门关键词",
        },
    },
    {
        path: "/student/storeoperation/index",
        name: "shopOperation",
        component: () => import("../views/student/roadShow/Index"),
        meta: {
            title: "店铺运营分析答辩",
        },
    },
    {
        path: "/student/customerInfo/index",
        name: "customerInfo",
        redirect: '/student/customerInfo/layout',
        component: () => import("../views/student/customerInfo/index"),
        children: [
            {
                path: "/student/customerInfo/layout",
                name: "customerInfoLayout",
                component: () => import("../views/student/customerInfo/customerInfoLayout"),
                meta: {
                    title: "客服数据分析",
                },
            }
        ]
    },
    {
        path: "/shopDiscount/index",
        name: "shopDiscount",
        component: studentOperationPromotion,
        children: [
            {
                path: "/student/operationpromotion/storepromotion",
                name: "operationPromotionStorePromotion",
                component: () =>
                    import(
                        "../views/student/dataOperation/StoreMarketing/StorePromotion"
                    ),
                meta: {
                    title: "店铺促销",
                },
            },
            {
                path: "/student/operationpromotion/storepromotionadd",
                name: "operationPromotionStorePromotionAdd",
                redirect: "/student/operationpromotion/storepromotionone",
                component: () =>
                    import(
                        "../views/student/dataOperation/StoreMarketing/operationPromotionStorePromotionAdd"
                    ),
                children: [
                    {
                        path: "/student/operationpromotion/storepromotionone",
                        name: "operationPromotionStorePromotionOne",
                        component: () =>
                            import(
                                "../components/student/EstablishPromotion/operationPromotionStorePromotionOne"
                            ),
                        meta: {
                            title: "创建促销",
                        },
                    },
                    {
                        path: "/student/operationpromotion/storepromotiontwo",
                        name: "operationPromotionStorePromotionTwo",
                        component: () =>
                            import(
                                "../components/student/EstablishPromotion/operationPromotionStorePromotionTwo"
                            ),
                        meta: {
                            title: "创建促销",
                        },
                    },
                ],
                meta: {
                    title: "创建促销",
                },
            },
            {
                path: "/student/operationpromotion/setpromotion",
                name: "operationPromotionSetPromotion",
                component: () =>
                    import(
                        "../views/student/dataOperation/StoreMarketing/SetPromotion"
                    ),
                meta: {
                    title: "套装促销",
                },
            },
            {
                path: "/student/operationpromotion/setpromotionadd",
                name: "operationPromotionSetPromotionAdd",
                redirect: "/student/operationpromotion/setpromotionaone",
                component: () =>
                    import(
                        "../views/student/dataOperation/StoreMarketing/SetPromotionAdd"
                    ),
                children: [
                    {
                        path: "/student/operationpromotion/setpromotionaone",
                        name: "operationPromotionSetPromotionOne",
                        component: () =>
                            import(
                                "../components/student/EstablishSetMenu/SetPromotionOne"
                            ),
                        meta: {
                            title: "创建套装促销",
                        },
                    },
                ],
                meta: {
                    title: "套装促销",
                },
            },
            {
                path: "/student/operationpromotion/coupon",
                name: "operationPromotionCoupon",
                component: () =>
                    import("../views/student/dataOperation/StoreMarketing/Coupon"),
                meta: {
                    title: "优惠券",
                },
            },
            {
                path: "/student/operationpromotion/couponAdd",
                name: "operationPromotionCouponAdd",
                redirect: "/student/operationpromotion/couponone",
                component: () =>
                    import(
                        "../views/student/dataOperation/StoreMarketing/CouponAdd"
                    ),
                children: [
                    {
                        path: "/student/operationpromotion/couponone",
                        name: "operationPromotionCouponOne",
                        component: () =>
                            import(
                                "../components/student/EstablishCoupon/CouponOne"
                            ),
                        meta: {
                            title: "创建优惠",
                        },
                    },
                    {
                        path: "/student/operationpromotion/coupontwo",
                        name: "operationPromotionCouponTwo",
                        component: () =>
                            import(
                                "../components/student/EstablishCoupon/CouponTwo"
                            ),
                        meta: {
                            title: "创建优惠",
                        },
                    },
                ],
                meta: {
                    title: "新建优惠",
                },
            },
        ],
    },
    {
        name: "ShopDrainage",
        path: "/ShopDrainage/index",
        component: studentOperationPromotion,
        children: [
            {
                path: "/student/operationpromotion/promotionmanagement",
                name: "operationPromotionPromotionManagement",
                component: () =>
                    import(
                        "../views/student/dataOperation/ShopDrainage/PromotionManagement"
                    ),
                meta: {
                    title: "推广管理",
                },
            },
            {
                path: "student/operationpromotion/boothadvertisingadd",
                name: "roadShowBoothAdvertisingAdd",
                redirect: "/student/roadshow/boothadvertisingone",
                component: () =>
                    import("../views/student/roadShow/BoothAdvertisingAdd.vue"),
                children: [
                    {
                        path: "/student/operationpromotion/boothadvertisingone",
                        name: "PlanStepOne",
                        component: () =>
                            import(
                                "../components/student/EstablishPlan/PlanStepOne.vue"
                            ),
                        meta: {
                            title: "创建计划",
                        },
                    },
                    {
                        path: "/student/operationpromotion/boothadvertisingtwo",
                        name: "PlanStepTwo",
                        component: () =>
                            import(
                                "../components/student/EstablishPlan/PlanStepTwo.vue"
                            ),
                        meta: {
                            title: "创建计划",
                        },
                    },
                    {
                        path: "/student/operationpromotion/boothadvertisingthree",
                        name: "PlanStepThree",
                        component: () =>
                            import(
                                "../components/student/EstablishPlan/PlanStepThree.vue"
                            ),
                        meta: {
                            title: "创建计划",
                        },
                    },
                    {
                        path: "/student/operationpromotion/boothadvertisingsucceed",
                        name: "PlanStepSucceed",
                        component: () =>
                            import(
                                "../components/student/EstablishPlan/PlanStepSucceed.vue"
                            ),
                        meta: {
                            title: "创建成功",
                        },
                    },
                ],
            },
            {
                path: "/student/operationpromotion/searchads",
                name: "operationPromotionSearchAds",
                component: () =>
                    import("../views/student/dataOperation/ShopDrainage/SearchAds"),
                meta: {
                    title: "搜索广告",
                },
            },
            //搜索广告--推广管理--创建计划步骤
            {
                path: "student/operationpromotion/searchads",
                name: "studentCreatePlan",
                component: studentCreatePlanLayout,
                children: [
                    {
                        path: "/student/operationpromotion/searchads/createplanstepone",
                        name: "CreatePlanStepOne",
                        component: () =>
                            import(
                                "../components/student/operationPromotion/shopDrainage/promotionPlan/CreatePlanStepOne.vue"
                            ),
                        meta: {
                            title: "创建计划",
                        },
                    },
                    {
                        path: "/student/operationpromotion/searchads/createplansteptwo",
                        name: "CreatePlanStepTwo",
                        component: () =>
                            import(
                                "../components/student/operationPromotion/shopDrainage/promotionPlan/CreatePlanStepTwo.vue"
                            ),
                        meta: {
                            title: "创建计划",
                        },
                    },
                    {
                        path: "/student/operationpromotion/searchads/createplanstepthree",
                        name: "CreatePlanStepThree",
                        component: () =>
                            import(
                                "../components/student/operationPromotion/shopDrainage/promotionPlan/CreatePlanStepThree.vue"
                            ),
                        meta: {
                            title: "创建计划",
                        },
                    },
                    {
                        path: "/student/operationpromotion/searchads/createplanstepfour",
                        name: "CreatePlanStepFour",
                        component: () =>
                            import(
                                "../components/student/operationPromotion/shopDrainage/promotionPlan/CreatePlanStepFour.vue"
                            ),
                        meta: {
                            title: "创建计划成功",
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/student/operationpromotion/shopcustomeranalysis",
        name: "operationPromotionShopCustomerAnalysis",
        component: () =>
            import("../views/student/dataOperation/ShopDrainage/CustomerAnalysis"),
        meta: {
            title: "数据分析",
        },
    },
    {
        path: "/student/kefu",
        name: "shopCustomerService",
        component: () => import("../views/student/customerService/Index.vue"),
        meta: {
            title: "客服竞赛模块",
        },
    },
    {
        path: "/student/newKefu",
        name: "NewShopCustomerService",
        component: () => import("../views/student/customerServiceNew/CustomerService.vue"),
        meta: {
            title: "客服竞赛模块",
        },
    },
    {
        path: "/student/kefugoods",
        name: "shopCustomerServiceGoodsDetail",
        component: () => import("../views/student/customerService/KefuGoodsDetail"),
        meta: {
            title: "客服商品详情页",
        },
    },
    {
        path: "/teacher",
        redirect: "/teacher/index",
        component: teacherLayout,
        name: "teacherGradeIndex",
        children: [
            {
                path: "/teacher/index",
                name: "teacherIndex",
                component: () => import("../views/teacher/Index"),
                meta: {
                    title: "评委评分主页",
                },
            },
            {
                path: "/teacher/dataAnalysis/index",
                name: "dataAnalysis",
                component: () => import("../views/teacher/dataAnalysis/Index.vue"),
                meta: {
                    title: "数据分析模块",
                },
            },
            {
                path: "/teacher/dataAnalysis/score",
                name: "dataAnalysisScore",
                component: () => import("../views/teacher/dataAnalysis/Score.vue"),
                meta: {
                    title: "评分管理",
                },
            },
        ],
    },
    {
        path: "/student/store/index",
        name: "studentStoreIndex",
        component: () => import("../views/student/StoreDetail/Index"),
        meta: {
            title: "店铺首页",
        },
    },
    {
        path: "/student/store/gooddetail",
        name: "studentStoreGoodDetail",
        component: () => import("../views/student/StoreDetail/GoodDetail"),
        meta: {
            title: "商品详情页",
        },
    },
    {
        path: "/teacher/student/store",
        name: "teacherStudentStore",
        component: () => import("../views/teacher/studentStoreDetail/Index"),
        meta: {
            title: "评委预览选手店铺主页",
        },
    },
    {
        path: "/teacher/student/goods",
        name: "teacherStudentGoods",
        component: () => import("../views/teacher/studentStoreDetail/Goods"),
        meta: {
            title: "评委预览选手商品主页",
        },
    },
    {
        path: "/student/trainmanage",
        redirect: "/student/trainmanage/index",
        component: studentTrainManage,
        children: [
            {
                path: "/student/trainmanage/index",
                name: "trainManage",
                component: TrainLayout,
                children: [
                    {
                        path: "/student/trainmanage/index",
                        name: "trainManageIndex",
                        component: () =>
                            import("../views/student/trainManage/Index.vue"),
                        meta: {
                            title: "训练管理-训练信息",
                        },
                    },
                    {
                        path: "/student/trainmanage/studenttrainlist",
                        name: "StudentTrainList",
                        component: () =>
                            import(
                                "../views/student/trainManage/StudentTrainList.vue"
                            ),
                        meta: {
                            title: "学生训练列表",
                            type: 2, //2是训练
                        },
                    },
                    {
                        path: "/student/trainmanage/traindetail",
                        name: "trainDetail",
                        component: () =>
                            import("../views/student/trainManage/TrainDetail"),
                        meta: {
                            title: "训练详情",
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/student/trainmanage/store",
        name: "studentTrainManageStore",
        component: () =>
            import("../views/student/trainManage/studentTrainManageStore"),
        meta: {
            title: "训练管理——店铺首页",
        },
    },
    {
        path: "/student/theorytrain/index",
        name: "studentTrainIndex",
        component: () => import("../views/student/studentTheory/studentTheoryIndex"),
        meta: {
            title: "答题页",
        },
    },
    {
        path: "/student/dataSummarization/index",
        name: "dataSummarization",
        component: () => import("../views/student/dataSummarization/Index.vue"),
        meta: {
            title: "数据汇总",
        },
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router;
